! function (X, Y, G, J) {
    "use strict";
    Y = void 0 !== Y && Y.Math == Math ? Y : "undefined" != typeof self && self.Math == Math ? self : Function("return this")(), X.fn.dropdown = function (z) {
        var P, H = X(this),
            j = X(G),
            N = H.selector || "",
            U = "ontouchstart" in G.documentElement,
            K = (new Date).getTime(),
            W = [],
            B = z,
            $ = "string" == typeof B,
            Q = [].slice.call(arguments, 1);
        return H.each(function (n) {
            var e, t, i, a, o, s, r, m, h = X.isPlainObject(z) ? X.extend(!0, {}, X.fn.dropdown.settings, z) : X.extend({}, X.fn.dropdown.settings),
                g = h.className,
                c = h.message,
                l = h.fields,
                p = h.keys,
                b = h.metadata,
                u = h.namespace,
                d = h.regExp,
                w = h.selector,
                v = h.error,
                f = h.templates,
                x = "." + u,
                C = "module-" + u,
                S = X(this),
                y = X(h.context),
                A = S.find(w.text),
                T = S.find(w.search),
                k = S.find(w.sizer),
                L = S.find(w.input),
                I = S.find(w.icon),
                D = 0 < S.prev().find(w.text).length ? S.prev().find(w.text) : S.prev(),
                q = S.children(w.menu),
                R = q.find(w.item),
                O = !1,
                V = !1,
                E = !1,
                M = this,
                F = S.data(C);
            m = {
                initialize: function () {
                    m.debug("Initializing dropdown", h), m.is.alreadySetup() ? m.setup.reference() : (m.setup.layout(), h.values && m.change.values(h.values), m.refreshData(), m.save.defaults(), m.restore.selected(), m.create.id(), m.bind.events(), m.observeChanges(), m.instantiate())
                },
                instantiate: function () {
                    m.verbose("Storing instance of dropdown", m), F = m, S.data(C, m)
                },
                destroy: function () {
                    m.verbose("Destroying previous dropdown", S), m.remove.tabbable(), S.off(x).removeData(C), q.off(x), j.off(a), m.disconnect.menuObserver(), m.disconnect.selectObserver()
                },
                observeChanges: function () {
                    "MutationObserver" in Y && (s = new MutationObserver(m.event.select.mutation), r = new MutationObserver(m.event.menu.mutation), m.debug("Setting up mutation observer", s, r), m.observe.select(), m.observe.menu())
                },
                disconnect: {
                    menuObserver: function () {
                        r && r.disconnect()
                    },
                    selectObserver: function () {
                        s && s.disconnect()
                    }
                },
                observe: {
                    select: function () {
                        m.has.input() && s.observe(S[0], {
                            childList: !0,
                            subtree: !0
                        })
                    },
                    menu: function () {
                        m.has.menu() && r.observe(q[0], {
                            childList: !0,
                            subtree: !0
                        })
                    }
                },
                create: {
                    id: function () {
                        o = (Math.random().toString(16) + "000000000").substr(2, 8), a = "." + o, m.verbose("Creating unique id for element", o)
                    },
                    userChoice: function (e) {
                        var n, i, a;
                        return !!(e = e || m.get.userValues()) && (e = X.isArray(e) ? e : [e], X.each(e, function (e, t) {
                            !1 === m.get.item(t) && (a = h.templates.addition(m.add.variables(c.addResult, t)), i = X("<div />").html(a).attr("data-" + b.value, t).attr("data-" + b.text, t).addClass(g.addition).addClass(g.item), h.hideAdditions && i.addClass(g.hidden), n = n === J ? i : n.add(i), m.verbose("Creating user choices for value", t, i))
                        }), n)
                    },
                    userLabels: function (e) {
                        var t = m.get.userValues();
                        t && (m.debug("Adding user labels", t), X.each(t, function (e, t) {
                            m.verbose("Adding custom user value"), m.add.label(t, t)
                        }))
                    },
                    menu: function () {
                        q = X("<div />").addClass(g.menu).appendTo(S)
                    },
                    sizer: function () {
                        k = X("<span />").addClass(g.sizer).insertAfter(T)
                    }
                },
                search: function (e) {
                    e = e !== J ? e : m.get.query(), m.verbose("Searching for query", e), m.has.minCharacters(e) ? m.filter(e) : m.hide()
                },
                select: {
                    firstUnfiltered: function () {
                        m.verbose("Selecting first non-filtered element"), m.remove.selectedItem(), R.not(w.unselectable).not(w.addition + w.hidden).eq(0).addClass(g.selected)
                    },
                    nextAvailable: function (e) {
                        var t = (e = e.eq(0)).nextAll(w.item).not(w.unselectable).eq(0),
                            n = e.prevAll(w.item).not(w.unselectable).eq(0);
                        0 < t.length ? (m.verbose("Moving selection to", t), t.addClass(g.selected)) : (m.verbose("Moving selection to", n), n.addClass(g.selected))
                    }
                },
                setup: {
                    api: function () {
                        var e = {
                            debug: h.debug,
                            urlData: {
                                value: m.get.value(),
                                query: m.get.query()
                            },
                            on: !1
                        };
                        m.verbose("First request, initializing API"), S.api(e)
                    },
                    layout: function () {
                        S.is("select") && (m.setup.select(), m.setup.returnedObject()), m.has.menu() || m.create.menu(), m.is.search() && !m.has.search() && (m.verbose("Adding search input"), T = X("<input />").addClass(g.search).prop("autocomplete", "off").insertBefore(A)), m.is.multiple() && m.is.searchSelection() && !m.has.sizer() && m.create.sizer(), h.allowTab && m.set.tabbable()
                    },
                    select: function () {
                        var e = m.get.selectValues();
                        m.debug("Dropdown initialized on a select", e), S.is("select") && (L = S), 0 < L.parent(w.dropdown).length ? (m.debug("UI dropdown already exists. Creating dropdown menu only"), S = L.closest(w.dropdown), m.has.menu() || m.create.menu(), q = S.children(w.menu), m.setup.menu(e)) : (m.debug("Creating entire dropdown from select"), S = X("<div />").attr("class", L.attr("class")).addClass(g.selection).addClass(g.dropdown).html(f.dropdown(e)).insertBefore(L), L.hasClass(g.multiple) && !1 === L.prop("multiple") && (m.error(v.missingMultiple), L.prop("multiple", !0)), L.is("[multiple]") && m.set.multiple(), L.prop("disabled") && (m.debug("Disabling dropdown"), S.addClass(g.disabled)), L.removeAttr("class").detach().prependTo(S)), m.refresh()
                    },
                    menu: function (e) {
                        q.html(f.menu(e, l)), R = q.find(w.item)
                    },
                    reference: function () {
                        m.debug("Dropdown behavior was called on select, replacing with closest dropdown"), S = S.parent(w.dropdown), F = S.data(C), M = S.get(0), m.refresh(), m.setup.returnedObject()
                    },
                    returnedObject: function () {
                        var e = H.slice(0, n),
                            t = H.slice(n + 1);
                        H = e.add(S).add(t)
                    }
                },
                refresh: function () {
                    m.refreshSelectors(), m.refreshData()
                },
                refreshItems: function () {
                    R = q.find(w.item)
                },
                refreshSelectors: function () {
                    m.verbose("Refreshing selector cache"), A = S.find(w.text), T = S.find(w.search), L = S.find(w.input), I = S.find(w.icon), D = 0 < S.prev().find(w.text).length ? S.prev().find(w.text) : S.prev(), q = S.children(w.menu), R = q.find(w.item)
                },
                refreshData: function () {
                    m.verbose("Refreshing cached metadata"), R.removeData(b.text).removeData(b.value)
                },
                clearData: function () {
                    m.verbose("Clearing metadata"), R.removeData(b.text).removeData(b.value), S.removeData(b.defaultText).removeData(b.defaultValue).removeData(b.placeholderText)
                },
                toggle: function () {
                    m.verbose("Toggling menu visibility"), m.is.active() ? m.hide() : m.show()
                },
                show: function (e) {
                    if (e = X.isFunction(e) ? e : function () { }, !m.can.show() && m.is.remote() && (m.debug("No API results retrieved, searching before show"), m.queryRemote(m.get.query(), m.show)), m.can.show() && !m.is.active()) {
                        if (m.debug("Showing dropdown"), !m.has.message() || m.has.maxSelections() || m.has.allResultsFiltered() || m.remove.message(), m.is.allFiltered()) return !0;
                        !1 !== h.onShow.call(M) && m.animate.show(function () {
                            m.can.click() && m.bind.intent(), m.has.menuSearch() && m.focusSearch(), m.set.visible(), e.call(M)
                        })
                    }
                },
                hide: function (e) {
                    e = X.isFunction(e) ? e : function () { }, m.is.active() && !m.is.animatingOutward() && (m.debug("Hiding dropdown"), !1 !== h.onHide.call(M) && m.animate.hide(function () {
                        m.remove.visible(), e.call(M)
                    }))
                },
                hideOthers: function () {
                    m.verbose("Finding other dropdowns to hide"), H.not(S).has(w.menu + "." + g.visible).dropdown("hide")
                },
                hideMenu: function () {
                    m.verbose("Hiding menu  instantaneously"), m.remove.active(), m.remove.visible(), q.transition("hide")
                },
                hideSubMenus: function () {
                    var e = q.children(w.item).find(w.menu);
                    m.verbose("Hiding sub menus", e), e.transition("hide")
                },
                bind: {
                    events: function () {
                        U && m.bind.touchEvents(), m.bind.keyboardEvents(), m.bind.inputEvents(), m.bind.mouseEvents()
                    },
                    touchEvents: function () {
                        m.debug("Touch device detected binding additional touch events"), m.is.searchSelection() || m.is.single() && S.on("touchstart" + x, m.event.test.toggle), q.on("touchstart" + x, w.item, m.event.item.mouseenter)
                    },
                    keyboardEvents: function () {
                        m.verbose("Binding keyboard events"), S.on("keydown" + x, m.event.keydown), m.has.search() && S.on(m.get.inputEvent() + x, w.search, m.event.input), m.is.multiple() && j.on("keydown" + a, m.event.document.keydown)
                    },
                    inputEvents: function () {
                        m.verbose("Binding input change events"), S.on("change" + x, w.input, m.event.change)
                    },
                    mouseEvents: function () {
                        m.verbose("Binding mouse events"), m.is.multiple() && S.on("click" + x, w.label, m.event.label.click).on("click" + x, w.remove, m.event.remove.click), m.is.searchSelection() ? (S.on("mousedown" + x, m.event.mousedown).on("mouseup" + x, m.event.mouseup).on("mousedown" + x, w.menu, m.event.menu.mousedown).on("mouseup" + x, w.menu, m.event.menu.mouseup).on("click" + x, w.icon, m.event.icon.click).on("focus" + x, w.search, m.event.search.focus).on("click" + x, w.search, m.event.search.focus).on("blur" + x, w.search, m.event.search.blur).on("click" + x, w.text, m.event.text.focus), m.is.multiple() && S.on("click" + x, m.event.click)) : ("click" == h.on ? S.on("click" + x, m.event.test.toggle) : "hover" == h.on ? S.on("mouseenter" + x, m.delay.show).on("mouseleave" + x, m.delay.hide) : S.on(h.on + x, m.toggle), S.on("click" + x, w.icon, m.event.icon.click).on("mousedown" + x, m.event.mousedown).on("mouseup" + x, m.event.mouseup).on("focus" + x, m.event.focus), m.has.menuSearch() ? S.on("blur" + x, w.search, m.event.search.blur) : S.on("blur" + x, m.event.blur)), q.on("mouseenter" + x, w.item, m.event.item.mouseenter).on("mouseleave" + x, w.item, m.event.item.mouseleave).on("click" + x, w.item, m.event.item.click)
                    },
                    intent: function () {
                        m.verbose("Binding hide intent event to document"), U && j.on("touchstart" + a, m.event.test.touch).on("touchmove" + a, m.event.test.touch), j.on("click" + a, m.event.test.hide)
                    }
                },
                unbind: {
                    intent: function () {
                        m.verbose("Removing hide intent event from document"), U && j.off("touchstart" + a).off("touchmove" + a), j.off("click" + a)
                    }
                },
                filter: function (e) {
                    var t = e !== J ? e : m.get.query(),
                        n = function () {
                            m.is.multiple() && m.filterActive(), (e || !e && 0 == m.get.activeItem().length) && m.select.firstUnfiltered(), m.has.allResultsFiltered() ? h.onNoResults.call(M, t) ? h.allowAdditions ? h.hideAdditions && (m.verbose("User addition with no menu, setting empty style"), m.set.empty(), m.hideMenu()) : (m.verbose("All items filtered, showing message", t), m.add.message(c.noResults)) : (m.verbose("All items filtered, hiding dropdown", t), m.hideMenu()) : (m.remove.empty(), m.remove.message()), h.allowAdditions && m.add.userSuggestion(e), m.is.searchSelection() && m.can.show() && m.is.focusedOnSearch() && m.show()
                        };
                    h.useLabels && m.has.maxSelections() || (h.apiSettings ? m.can.useAPI() ? m.queryRemote(t, function () {
                        h.filterRemoteData && m.filterItems(t), n()
                    }) : m.error(v.noAPI) : (m.filterItems(t), n()))
                },
                queryRemote: function (e, n) {
                    var t = {
                        errorDuration: !1,
                        cache: "local",
                        throttle: h.throttle,
                        urlData: {
                            query: e
                        },
                        onError: function () {
                            m.add.message(c.serverError), n()
                        },
                        onFailure: function () {
                            m.add.message(c.serverError), n()
                        },
                        onSuccess: function (e) {
                            var t = e[l.remoteValues];
                            X.isArray(t) && 0 < t.length ? (m.remove.message(), m.setup.menu({
                                values: e[l.remoteValues]
                            })) : m.add.message(c.noResults), n()
                        }
                    };
                    S.api("get request") || m.setup.api(), t = X.extend(!0, {}, t, h.apiSettings), S.api("setting", t).api("query")
                },
                filterItems: function (e) {
                    var i = e !== J ? e : m.get.query(),
                        a = null,
                        t = m.escape.string(i),
                        o = new RegExp("^" + t, "igm");
                    m.has.query() && (a = [], m.verbose("Searching for matching values", i), R.each(function () {
                        var e, t, n = X(this);
                        if ("both" == h.match || "text" == h.match) {
                            if (-1 !== (e = String(m.get.choiceText(n, !1))).search(o)) return a.push(this), !0;
                            if ("exact" === h.fullTextSearch && m.exactSearch(i, e)) return a.push(this), !0;
                            if (!0 === h.fullTextSearch && m.fuzzySearch(i, e)) return a.push(this), !0
                        }
                        if ("both" == h.match || "value" == h.match) {
                            if (-1 !== (t = String(m.get.choiceValue(n, e))).search(o)) return a.push(this), !0;
                            if ("exact" === h.fullTextSearch && m.exactSearch(i, t)) return a.push(this), !0;
                            if (!0 === h.fullTextSearch && m.fuzzySearch(i, t)) return a.push(this), !0
                        }
                    })), m.debug("Showing only matched items", i), m.remove.filteredItem(), a && R.not(a).addClass(g.filtered)
                },
                fuzzySearch: function (e, t) {
                    var n = t.length,
                        i = e.length;
                    if (e = e.toLowerCase(), t = t.toLowerCase(), n < i) return !1;
                    if (i === n) return e === t;
                    e: for (var a = 0, o = 0; a < i; a++) {
                        for (var s = e.charCodeAt(a); o < n;)
                            if (t.charCodeAt(o++) === s) continue e;
                        return !1
                    }
                    return !0
                },
                exactSearch: function (e, t) {
                    return e = e.toLowerCase(), -1 < (t = t.toLowerCase()).indexOf(e)
                },
                filterActive: function () {
                    h.useLabels && R.filter("." + g.active).addClass(g.filtered)
                },
                focusSearch: function (e) {
                    m.has.search() && !m.is.focusedOnSearch() && (e ? (S.off("focus" + x, w.search), T.focus(), S.on("focus" + x, w.search, m.event.search.focus)) : T.focus())
                },
                forceSelection: function () {
                    var e = R.not(g.filtered).filter("." + g.selected).eq(0),
                        t = R.not(g.filtered).filter("." + g.active).eq(0),
                        n = 0 < e.length ? e : t;
                    if (0 < n.length && !m.is.multiple()) return m.debug("Forcing partial selection to selected item", n), void m.event.item.click.call(n, {}, !0);
                    h.allowAdditions && m.set.selected(m.get.query()), m.remove.searchTerm()
                },
                change: {
                    values: function (e) {
                        h.allowAdditions || m.clear(), m.debug("Creating dropdown with specified values", e), m.setup.menu({
                            values: e
                        }), X.each(e, function (e, t) {
                            if (1 == t.selected) return m.debug("Setting initial selection to", t.value), m.set.selected(t.value), !0
                        })
                    }
                },
                event: {
                    change: function () {
                        E || (m.debug("Input changed, updating selection"), m.set.selected())
                    },
                    focus: function () {
                        h.showOnFocus && !O && m.is.hidden() && !t && m.show()
                    },
                    blur: function (e) {
                        t = G.activeElement === this, O || t || (m.remove.activeLabel(), m.hide())
                    },
                    mousedown: function () {
                        m.is.searchSelection() ? i = !0 : O = !0
                    },
                    mouseup: function () {
                        m.is.searchSelection() ? i = !1 : O = !1
                    },
                    click: function (e) {
                        X(e.target).is(S) && (m.is.focusedOnSearch() ? m.show() : m.focusSearch())
                    },
                    search: {
                        focus: function () {
                            O = !0, m.is.multiple() && m.remove.activeLabel(), h.showOnFocus && m.search()
                        },
                        blur: function (e) {
                            t = G.activeElement === this, m.is.searchSelection() && !i && (V || t || (h.forceSelection && m.forceSelection(), m.hide())), i = !1
                        }
                    },
                    icon: {
                        click: function (e) {
                            I.hasClass(g.clear) ? m.clear() : m.can.click() && m.toggle()
                        }
                    },
                    text: {
                        focus: function (e) {
                            O = !0, m.focusSearch()
                        }
                    },
                    input: function (e) {
                        (m.is.multiple() || m.is.searchSelection()) && m.set.filtered(), clearTimeout(m.timer), m.timer = setTimeout(m.search, h.delay.search)
                    },
                    label: {
                        click: function (e) {
                            var t = X(this),
                                n = S.find(w.label),
                                i = n.filter("." + g.active),
                                a = t.nextAll("." + g.active),
                                o = t.prevAll("." + g.active),
                                s = 0 < a.length ? t.nextUntil(a).add(i).add(t) : t.prevUntil(o).add(i).add(t);
                            e.shiftKey ? (i.removeClass(g.active), s.addClass(g.active)) : e.ctrlKey ? t.toggleClass(g.active) : (i.removeClass(g.active), t.addClass(g.active)), h.onLabelSelect.apply(this, n.filter("." + g.active))
                        }
                    },
                    remove: {
                        click: function () {
                            var e = X(this).parent();
                            e.hasClass(g.active) ? m.remove.activeLabels() : m.remove.activeLabels(e)
                        }
                    },
                    test: {
                        toggle: function (e) {
                            var t = m.is.multiple() ? m.show : m.toggle;
                            m.is.bubbledLabelClick(e) || m.is.bubbledIconClick(e) || m.determine.eventOnElement(e, t) && e.preventDefault()
                        },
                        touch: function (e) {
                            m.determine.eventOnElement(e, function () {
                                "touchstart" == e.type ? m.timer = setTimeout(function () {
                                    m.hide()
                                }, h.delay.touch) : "touchmove" == e.type && clearTimeout(m.timer)
                            }), e.stopPropagation()
                        },
                        hide: function (e) {
                            m.determine.eventInModule(e, m.hide)
                        }
                    },
                    select: {
                        mutation: function (e) {
                            m.debug("<select> modified, recreating menu");
                            var n = !1;
                            X.each(e, function (e, t) {
                                if (X(t.target).is("select") || X(t.addedNodes).is("select")) return n = !0
                            }), n && (m.disconnect.selectObserver(), m.refresh(), m.setup.select(), m.set.selected(), m.observe.select())
                        }
                    },
                    menu: {
                        mutation: function (e) {
                            var t = e[0],
                                n = t.addedNodes ? X(t.addedNodes[0]) : X(!1),
                                i = t.removedNodes ? X(t.removedNodes[0]) : X(!1),
                                a = n.add(i),
                                o = a.is(w.addition) || 0 < a.closest(w.addition).length,
                                s = a.is(w.message) || 0 < a.closest(w.message).length;
                            o || s ? (m.debug("Updating item selector cache"), m.refreshItems()) : (m.debug("Menu modified, updating selector cache"), m.refresh())
                        },
                        mousedown: function () {
                            V = !0
                        },
                        mouseup: function () {
                            V = !1
                        }
                    },
                    item: {
                        mouseenter: function (e) {
                            var t = X(e.target),
                                n = X(this),
                                i = n.children(w.menu),
                                a = n.siblings(w.item).children(w.menu),
                                o = 0 < i.length;
                            !(0 < i.find(t).length) && o && (clearTimeout(m.itemTimer), m.itemTimer = setTimeout(function () {
                                m.verbose("Showing sub-menu", i), X.each(a, function () {
                                    m.animate.hide(!1, X(this))
                                }), m.animate.show(!1, i)
                            }, h.delay.show), e.preventDefault())
                        },
                        mouseleave: function (e) {
                            var t = X(this).children(w.menu);
                            0 < t.length && (clearTimeout(m.itemTimer), m.itemTimer = setTimeout(function () {
                                m.verbose("Hiding sub-menu", t), m.animate.hide(!1, t)
                            }, h.delay.hide))
                        },
                        click: function (e, t) {
                            var n = X(this),
                                i = X(e ? e.target : ""),
                                a = n.find(w.menu),
                                o = m.get.choiceText(n),
                                s = m.get.choiceValue(n, o),
                                r = 0 < a.length,
                                l = 0 < a.find(i).length;
                            m.has.menuSearch() && X(G.activeElement).blur(), l || r && !h.allowCategorySelection || (m.is.searchSelection() && (h.allowAdditions && m.remove.userAddition(), m.remove.searchTerm(), m.is.focusedOnSearch() || 1 == t || m.focusSearch(!0)), h.useLabels || (m.remove.filteredItem(), m.set.scrollPosition(n)), m.determine.selectAction.call(this, o, s))
                        }
                    },
                    document: {
                        keydown: function (e) {
                            var t = e.which;
                            if (m.is.inObject(t, p)) {
                                var n = S.find(w.label),
                                    i = n.filter("." + g.active),
                                    a = (i.data(b.value), n.index(i)),
                                    o = n.length,
                                    s = 0 < i.length,
                                    r = 1 < i.length,
                                    l = 0 === a,
                                    c = a + 1 == o,
                                    u = m.is.searchSelection(),
                                    d = m.is.focusedOnSearch(),
                                    v = m.is.focused(),
                                    f = d && 0 === m.get.caretPosition();
                                if (u && !s && !d) return;
                                t == p.leftArrow ? !v && !f || s ? s && (e.shiftKey ? m.verbose("Adding previous label to selection") : (m.verbose("Selecting previous label"), n.removeClass(g.active)), l && !r ? i.addClass(g.active) : i.prev(w.siblingLabel).addClass(g.active).end(), e.preventDefault()) : (m.verbose("Selecting previous label"), n.last().addClass(g.active)) : t == p.rightArrow ? (v && !s && n.first().addClass(g.active), s && (e.shiftKey ? m.verbose("Adding next label to selection") : (m.verbose("Selecting next label"), n.removeClass(g.active)), c ? u ? d ? n.removeClass(g.active) : m.focusSearch() : r ? i.next(w.siblingLabel).addClass(g.active) : i.addClass(g.active) : i.next(w.siblingLabel).addClass(g.active), e.preventDefault())) : t == p.deleteKey || t == p.backspace ? s ? (m.verbose("Removing active labels"), c && u && !d && m.focusSearch(), i.last().next(w.siblingLabel).addClass(g.active), m.remove.activeLabels(i), e.preventDefault()) : f && !s && t == p.backspace && (m.verbose("Removing last label on input backspace"), i = n.last().addClass(g.active), m.remove.activeLabels(i)) : i.removeClass(g.active)
                            }
                        }
                    },
                    keydown: function (e) {
                        var t = e.which;
                        if (m.is.inObject(t, p)) {
                            var n, i = R.not(w.unselectable).filter("." + g.selected).eq(0),
                                a = q.children("." + g.active).eq(0),
                                o = 0 < i.length ? i : a,
                                s = 0 < o.length ? o.siblings(":not(." + g.filtered + ")").addBack() : q.children(":not(." + g.filtered + ")"),
                                r = o.children(w.menu),
                                l = o.closest(w.menu),
                                c = l.hasClass(g.visible) || l.hasClass(g.animating) || 0 < l.parent(w.menu).length,
                                u = 0 < r.length,
                                d = 0 < o.length,
                                v = 0 < o.not(w.unselectable).length,
                                f = t == p.delimiter && h.allowAdditions && m.is.multiple();
                            if (h.allowAdditions && h.hideAdditions && (t == p.enter || f) && v && (m.verbose("Selecting item from keyboard shortcut", o), m.event.item.click.call(o, e), m.is.searchSelection() && m.remove.searchTerm()), m.is.visible()) {
                                if ((t == p.enter || f) && (t == p.enter && d && u && !h.allowCategorySelection ? (m.verbose("Pressed enter on unselectable category, opening sub menu"), t = p.rightArrow) : v && (m.verbose("Selecting item from keyboard shortcut", o), m.event.item.click.call(o, e), m.is.searchSelection() && m.remove.searchTerm()), e.preventDefault()), d && (t == p.leftArrow && l[0] !== q[0] && (m.verbose("Left key pressed, closing sub-menu"), m.animate.hide(!1, l), o.removeClass(g.selected), l.closest(w.item).addClass(g.selected), e.preventDefault()), t == p.rightArrow && u && (m.verbose("Right key pressed, opening sub-menu"), m.animate.show(!1, r), o.removeClass(g.selected), r.find(w.item).eq(0).addClass(g.selected), e.preventDefault())), t == p.upArrow) {
                                    if (n = d && c ? o.prevAll(w.item + ":not(" + w.unselectable + ")").eq(0) : R.eq(0), s.index(n) < 0) return m.verbose("Up key pressed but reached top of current menu"), void e.preventDefault();
                                    m.verbose("Up key pressed, changing active item"), o.removeClass(g.selected), n.addClass(g.selected), m.set.scrollPosition(n), h.selectOnKeydown && m.is.single() && m.set.selectedItem(n), e.preventDefault()
                                }
                                if (t == p.downArrow) {
                                    if (0 === (n = d && c ? n = o.nextAll(w.item + ":not(" + w.unselectable + ")").eq(0) : R.eq(0)).length) return m.verbose("Down key pressed but reached bottom of current menu"), void e.preventDefault();
                                    m.verbose("Down key pressed, changing active item"), R.removeClass(g.selected), n.addClass(g.selected), m.set.scrollPosition(n), h.selectOnKeydown && m.is.single() && m.set.selectedItem(n), e.preventDefault()
                                }
                                t == p.pageUp && (m.scrollPage("up"), e.preventDefault()), t == p.pageDown && (m.scrollPage("down"), e.preventDefault()), t == p.escape && (m.verbose("Escape key pressed, closing dropdown"), m.hide())
                            } else f && e.preventDefault(), t != p.downArrow || m.is.visible() || (m.verbose("Down key pressed, showing dropdown"), m.show(), e.preventDefault())
                        } else m.has.search() || m.set.selectedLetter(String.fromCharCode(t))
                    }
                },
                trigger: {
                    change: function () {
                        var e = G.createEvent("HTMLEvents"),
                            t = L[0];
                        t && (m.verbose("Triggering native change event"), e.initEvent("change", !0, !1), t.dispatchEvent(e))
                    }
                },
                determine: {
                    selectAction: function (e, t) {
                        m.verbose("Determining action", h.action), X.isFunction(m.action[h.action]) ? (m.verbose("Triggering preset action", h.action, e, t), m.action[h.action].call(M, e, t, this)) : X.isFunction(h.action) ? (m.verbose("Triggering user action", h.action, e, t), h.action.call(M, e, t, this)) : m.error(v.action, h.action)
                    },
                    eventInModule: function (e, t) {
                        var n = X(e.target),
                            i = 0 < n.closest(G.documentElement).length,
                            a = 0 < n.closest(S).length;
                        return t = X.isFunction(t) ? t : function () { }, i && !a ? (m.verbose("Triggering event", t), t(), !0) : (m.verbose("Event occurred in dropdown, canceling callback"), !1)
                    },
                    eventOnElement: function (e, t) {
                        var n = X(e.target),
                            i = n.closest(w.siblingLabel),
                            a = G.body.contains(e.target),
                            o = 0 === S.find(i).length,
                            s = 0 === n.closest(q).length;
                        return t = X.isFunction(t) ? t : function () { }, a && o && s ? (m.verbose("Triggering event", t), t(), !0) : (m.verbose("Event occurred in dropdown menu, canceling callback"), !1)
                    }
                },
                action: {
                    nothing: function () { },
                    activate: function (e, t, n) {
                        if (t = t !== J ? t : e, m.can.activate(X(n))) {
                            if (m.set.selected(t, X(n)), m.is.multiple() && !m.is.allFiltered()) return;
                            m.hideAndClear()
                        }
                    },
                    select: function (e, t, n) {
                        if (t = t !== J ? t : e, m.can.activate(X(n))) {
                            if (m.set.value(t, e, X(n)), m.is.multiple() && !m.is.allFiltered()) return;
                            m.hideAndClear()
                        }
                    },
                    combo: function (e, t, n) {
                        t = t !== J ? t : e, m.set.selected(t, X(n)), m.hideAndClear()
                    },
                    hide: function (e, t, n) {
                        m.set.value(t, e, X(n)), m.hideAndClear()
                    }
                },
                get: {
                    id: function () {
                        return o
                    },
                    defaultText: function () {
                        return S.data(b.defaultText)
                    },
                    defaultValue: function () {
                        return S.data(b.defaultValue)
                    },
                    placeholderText: function () {
                        return "auto" != h.placeholder && "string" == typeof h.placeholder ? h.placeholder : S.data(b.placeholderText) || ""
                    },
                    text: function () {
                        return A.text()
                    },
                    query: function () {
                        return X.trim(T.val())
                    },
                    searchWidth: function (e) {
                        return e = e !== J ? e : T.val(), k.text(e), Math.ceil(k.width() + 1)
                    },
                    selectionCount: function () {
                        var e = m.get.values();
                        return m.is.multiple() ? X.isArray(e) ? e.length : 0 : "" !== m.get.value() ? 1 : 0
                    },
                    transition: function (e) {
                        return "auto" == h.transition ? m.is.upward(e) ? "slide up" : "slide down" : h.transition
                    },
                    userValues: function () {
                        var e = m.get.values();
                        return !!e && (e = X.isArray(e) ? e : [e], X.grep(e, function (e) {
                            return !1 === m.get.item(e)
                        }))
                    },
                    uniqueArray: function (n) {
                        return X.grep(n, function (e, t) {
                            return X.inArray(e, n) === t
                        })
                    },
                    caretPosition: function () {
                        var e, t, n = T.get(0);
                        return "selectionStart" in n ? n.selectionStart : G.selection ? (n.focus(), t = (e = G.selection.createRange()).text.length, e.moveStart("character", -n.value.length), e.text.length - t) : void 0
                    },
                    value: function () {
                        var e = 0 < L.length ? L.val() : S.data(b.value),
                            t = X.isArray(e) && 1 === e.length && "" === e[0];
                        return e === J || t ? "" : e
                    },
                    values: function () {
                        var e = m.get.value();
                        return "" === e ? "" : !m.has.selectInput() && m.is.multiple() ? "string" == typeof e ? e.split(h.delimiter) : "" : e
                    },
                    remoteValues: function () {
                        var e = m.get.values(),
                            i = !1;
                        return e && ("string" == typeof e && (e = [e]), X.each(e, function (e, t) {
                            var n = m.read.remoteData(t);
                            m.verbose("Restoring value from session data", n, t), n && (i || (i = {}), i[t] = n)
                        })), i
                    },
                    choiceText: function (e, t) {
                        if (t = t !== J ? t : h.preserveHTML, e) return 0 < e.find(w.menu).length && (m.verbose("Retrieving text of element with sub-menu"), (e = e.clone()).find(w.menu).remove(), e.find(w.menuIcon).remove()), e.data(b.text) !== J ? e.data(b.text) : t ? X.trim(e.html()) : X.trim(e.text())
                    },
                    choiceValue: function (e, t) {
                        return t = t || m.get.choiceText(e), !!e && (e.data(b.value) !== J ? String(e.data(b.value)) : "string" == typeof t ? X.trim(t.toLowerCase()) : String(t))
                    },
                    inputEvent: function () {
                        var e = T[0];
                        return !!e && (e.oninput !== J ? "input" : e.onpropertychange !== J ? "propertychange" : "keyup")
                    },
                    selectValues: function () {
                        var a = {
                            values: []
                        };
                        return S.find("option").each(function () {
                            var e = X(this),
                                t = e.html(),
                                n = e.attr("disabled"),
                                i = e.attr("value") !== J ? e.attr("value") : t;
                            "auto" === h.placeholder && "" === i ? a.placeholder = t : a.values.push({
                                name: t,
                                value: i,
                                disabled: n
                            })
                        }), h.placeholder && "auto" !== h.placeholder && (m.debug("Setting placeholder value to", h.placeholder), a.placeholder = h.placeholder), h.sortSelect ? (a.values.sort(function (e, t) {
                            return e.name > t.name ? 1 : -1
                        }), m.debug("Retrieved and sorted values from select", a)) : m.debug("Retrieved values from select", a), a
                    },
                    activeItem: function () {
                        return R.filter("." + g.active)
                    },
                    selectedItem: function () {
                        var e = R.not(w.unselectable).filter("." + g.selected);
                        return 0 < e.length ? e : R.eq(0)
                    },
                    itemWithAdditions: function (e) {
                        var t = m.get.item(e),
                            n = m.create.userChoice(e);
                        return n && 0 < n.length && (t = 0 < t.length ? t.add(n) : n), t
                    },
                    item: function (i, a) {
                        var e, o, s = !1;
                        return i = i !== J ? i : m.get.values() !== J ? m.get.values() : m.get.text(), e = o ? 0 < i.length : i !== J && null !== i, o = m.is.multiple() && X.isArray(i), a = "" === i || 0 === i || (a || !1), e && R.each(function () {
                            var e = X(this),
                                t = m.get.choiceText(e),
                                n = m.get.choiceValue(e, t);
                            if (null !== n && n !== J)
                                if (o) - 1 === X.inArray(String(n), i) && -1 === X.inArray(t, i) || (s = s ? s.add(e) : e);
                                else if (a) {
                                    if (m.verbose("Ambiguous dropdown value using strict type check", e, i), n === i || t === i) return s = e, !0
                                } else if (String(n) == String(i) || t == i) return m.verbose("Found select item by value", n, i), s = e, !0
                        }), s
                    }
                },
                check: {
                    maxSelections: function (e) {
                        return !h.maxSelections || ((e = e !== J ? e : m.get.selectionCount()) >= h.maxSelections ? (m.debug("Maximum selection count reached"), h.useLabels && (R.addClass(g.filtered), m.add.message(c.maxSelections)), !0) : (m.verbose("No longer at maximum selection count"), m.remove.message(), m.remove.filteredItem(), m.is.searchSelection() && m.filterItems(), !1))
                    }
                },
                restore: {
                    defaults: function () {
                        m.clear(), m.restore.defaultText(), m.restore.defaultValue()
                    },
                    defaultText: function () {
                        var e = m.get.defaultText();
                        e === m.get.placeholderText ? (m.debug("Restoring default placeholder text", e), m.set.placeholderText(e)) : (m.debug("Restoring default text", e), m.set.text(e))
                    },
                    placeholderText: function () {
                        m.set.placeholderText()
                    },
                    defaultValue: function () {
                        var e = m.get.defaultValue();
                        e !== J && (m.debug("Restoring default value", e), "" !== e ? (m.set.value(e), m.set.selected()) : (m.remove.activeItem(), m.remove.selectedItem()))
                    },
                    labels: function () {
                        h.allowAdditions && (h.useLabels || (m.error(v.labels), h.useLabels = !0), m.debug("Restoring selected values"), m.create.userLabels()), m.check.maxSelections()
                    },
                    selected: function () {
                        m.restore.values(), m.is.multiple() ? (m.debug("Restoring previously selected values and labels"), m.restore.labels()) : m.debug("Restoring previously selected values")
                    },
                    values: function () {
                        m.set.initialLoad(), h.apiSettings && h.saveRemoteData && m.get.remoteValues() ? m.restore.remoteValues() : m.set.selected(), m.remove.initialLoad()
                    },
                    remoteValues: function () {
                        var e = m.get.remoteValues();
                        m.debug("Recreating selected from session data", e), e && (m.is.single() ? X.each(e, function (e, t) {
                            m.set.text(t)
                        }) : X.each(e, function (e, t) {
                            m.add.label(e, t)
                        }))
                    }
                },
                read: {
                    remoteData: function (e) {
                        var t;
                        if (Y.Storage !== J) return (t = sessionStorage.getItem(e)) !== J && t;
                        m.error(v.noStorage)
                    }
                },
                save: {
                    defaults: function () {
                        m.save.defaultText(), m.save.placeholderText(), m.save.defaultValue()
                    },
                    defaultValue: function () {
                        var e = m.get.value();
                        m.verbose("Saving default value as", e), S.data(b.defaultValue, e)
                    },
                    defaultText: function () {
                        var e = m.get.text();
                        m.verbose("Saving default text as", e), S.data(b.defaultText, e)
                    },
                    placeholderText: function () {
                        var e;
                        !1 !== h.placeholder && A.hasClass(g.placeholder) && (e = m.get.text(), m.verbose("Saving placeholder text as", e), S.data(b.placeholderText, e))
                    },
                    remoteData: function (e, t) {
                        Y.Storage !== J ? (m.verbose("Saving remote data to session storage", t, e), sessionStorage.setItem(t, e)) : m.error(v.noStorage)
                    }
                },
                clear: function () {
                    m.is.multiple() && h.useLabels ? m.remove.labels() : (m.remove.activeItem(), m.remove.selectedItem()), m.set.placeholderText(), m.clearValue()
                },
                clearValue: function () {
                    m.set.value("")
                },
                scrollPage: function (e, t) {
                    var n, i, a = t || m.get.selectedItem(),
                        o = a.closest(w.menu),
                        s = o.outerHeight(),
                        r = o.scrollTop(),
                        l = R.eq(0).outerHeight(),
                        c = Math.floor(s / l),
                        u = (o.prop("scrollHeight"), "up" == e ? r - l * c : r + l * c),
                        d = R.not(w.unselectable);
                    i = "up" == e ? d.index(a) - c : d.index(a) + c, 0 < (n = ("up" == e ? 0 <= i : i < d.length) ? d.eq(i) : "up" == e ? d.first() : d.last()).length && (m.debug("Scrolling page", e, n), a.removeClass(g.selected), n.addClass(g.selected), h.selectOnKeydown && m.is.single() && m.set.selectedItem(n), o.scrollTop(u))
                },
                set: {
                    filtered: function () {
                        var e = m.is.multiple(),
                            t = m.is.searchSelection(),
                            n = e && t,
                            i = t ? m.get.query() : "",
                            a = "string" == typeof i && 0 < i.length,
                            o = m.get.searchWidth(),
                            s = "" !== i;
                        e && a && (m.verbose("Adjusting input width", o, h.glyphWidth), T.css("width", o)), a || n && s ? (m.verbose("Hiding placeholder text"), A.addClass(g.filtered)) : (!e || n && !s) && (m.verbose("Showing placeholder text"), A.removeClass(g.filtered))
                    },
                    empty: function () {
                        S.addClass(g.empty)
                    },
                    loading: function () {
                        S.addClass(g.loading)
                    },
                    placeholderText: function (e) {
                        e = e || m.get.placeholderText(), m.debug("Setting placeholder text", e), m.set.text(e), A.addClass(g.placeholder)
                    },
                    tabbable: function () {
                        m.is.searchSelection() ? (m.debug("Added tabindex to searchable dropdown"), T.val("").attr("tabindex", 0), q.attr("tabindex", -1)) : (m.debug("Added tabindex to dropdown"), S.attr("tabindex") === J && (S.attr("tabindex", 0), q.attr("tabindex", -1)))
                    },
                    initialLoad: function () {
                        m.verbose("Setting initial load"), e = !0
                    },
                    activeItem: function (e) {
                        h.allowAdditions && 0 < e.filter(w.addition).length ? e.addClass(g.filtered) : e.addClass(g.active)
                    },
                    partialSearch: function (e) {
                        var t = m.get.query().length;
                        T.val(e.substr(0, t))
                    },
                    scrollPosition: function (e, t) {
                        var n, i, a, o, s, r;
                        n = (e = e || m.get.selectedItem()).closest(w.menu), i = e && 0 < e.length, t = t !== J && t, e && 0 < n.length && i && (e.position().top, n.addClass(g.loading), a = (o = n.scrollTop()) - n.offset().top + e.offset().top, t || (r = o + n.height() < a + 5, s = a - 5 < o), m.debug("Scrolling to active item", a), (t || s || r) && n.scrollTop(a), n.removeClass(g.loading))
                    },
                    text: function (e) {
                        "select" !== h.action && ("combo" == h.action ? (m.debug("Changing combo button text", e, D), h.preserveHTML ? D.html(e) : D.text(e)) : (e !== m.get.placeholderText() && A.removeClass(g.placeholder), m.debug("Changing text", e, A), A.removeClass(g.filtered), h.preserveHTML ? A.html(e) : A.text(e)))
                    },
                    selectedItem: function (e) {
                        var t = m.get.choiceValue(e),
                            n = m.get.choiceText(e, !1),
                            i = m.get.choiceText(e, !0);
                        m.debug("Setting user selection to item", e), m.remove.activeItem(), m.set.partialSearch(n), m.set.activeItem(e), m.set.selected(t, e), m.set.text(i)
                    },
                    selectedLetter: function (e) {
                        var t, n = R.filter("." + g.selected),
                            i = 0 < n.length && m.has.firstLetter(n, e),
                            a = !1;
                        i && (t = n.nextAll(R).eq(0), m.has.firstLetter(t, e) && (a = t)), a || R.each(function () {
                            if (m.has.firstLetter(X(this), e)) return a = X(this), !1
                        }), a && (m.verbose("Scrolling to next value with letter", e), m.set.scrollPosition(a), n.removeClass(g.selected), a.addClass(g.selected), h.selectOnKeydown && m.is.single() && m.set.selectedItem(a))
                    },
                    direction: function (e) {
                        "auto" == h.direction ? (m.remove.upward(), m.can.openDownward(e) ? m.remove.upward(e) : m.set.upward(e), m.is.leftward(e) || m.can.openRightward(e) || m.set.leftward(e)) : "upward" == h.direction && m.set.upward(e)
                    },
                    upward: function (e) {
                        (e || S).addClass(g.upward)
                    },
                    leftward: function (e) {
                        (e || q).addClass(g.leftward)
                    },
                    value: function (e, t, n) {
                        var i = m.escape.value(e),
                            a = 0 < L.length,
                            o = m.get.values(),
                            s = e !== J ? String(e) : e;
                        if (a) {
                            if (!h.allowReselection && s == o && (m.verbose("Skipping value update already same value", e, o), !m.is.initialLoad())) return;
                            m.is.single() && m.has.selectInput() && m.can.extendSelect() && (m.debug("Adding user option", e), m.add.optionValue(e)), m.debug("Updating input value", i, o), E = !0, L.val(i), !1 === h.fireOnInit && m.is.initialLoad() ? m.debug("Input native change event ignored on initial load") : m.trigger.change(), E = !1
                        } else m.verbose("Storing value in metadata", i, L), i !== o && S.data(b.value, s);
                        m.is.single() && h.clearable && (i ? m.set.clearable() : m.remove.clearable()), !1 === h.fireOnInit && m.is.initialLoad() ? m.verbose("No callback on initial load", h.onChange) : h.onChange.call(M, e, t, n)
                    },
                    active: function () {
                        S.addClass(g.active)
                    },
                    multiple: function () {
                        S.addClass(g.multiple)
                    },
                    visible: function () {
                        S.addClass(g.visible)
                    },
                    exactly: function (e, t) {
                        m.debug("Setting selected to exact values"), m.clear(), m.set.selected(e, t)
                    },
                    selected: function (e, r) {
                        var l = m.is.multiple();
                        (r = h.allowAdditions ? r || m.get.itemWithAdditions(e) : r || m.get.item(e)) && (m.debug("Setting selected menu item to", r), m.is.multiple() && m.remove.searchWidth(), m.is.single() ? (m.remove.activeItem(), m.remove.selectedItem()) : h.useLabels && m.remove.selectedItem(), r.each(function () {
                            var e = X(this),
                                t = m.get.choiceText(e),
                                n = m.get.choiceValue(e, t),
                                i = e.hasClass(g.filtered),
                                a = e.hasClass(g.active),
                                o = e.hasClass(g.addition),
                                s = l && 1 == r.length;
                            l ? !a || o ? (h.apiSettings && h.saveRemoteData && m.save.remoteData(t, n), h.useLabels ? (m.add.label(n, t, s), m.add.value(n, t, e), m.set.activeItem(e), m.filterActive(), m.select.nextAvailable(r)) : (m.add.value(n, t, e), m.set.text(m.add.variables(c.count)), m.set.activeItem(e))) : i || (m.debug("Selected active value, removing label"), m.remove.selected(n)) : (h.apiSettings && h.saveRemoteData && m.save.remoteData(t, n), m.set.text(t), m.set.value(n, t, e), e.addClass(g.active).addClass(g.selected))
                        }))
                    },
                    clearable: function () {
                        I.addClass(g.clear)
                    }
                },
                add: {
                    label: function (e, t, n) {
                        var i, a = m.is.searchSelection() ? T : A,
                            o = m.escape.value(e);
                        h.ignoreCase && (o = o.toLowerCase()), i = X("<a />").addClass(g.label).attr("data-" + b.value, o).html(f.label(o, t)), i = h.onLabelCreate.call(i, o, t), m.has.label(e) ? m.debug("User selection already exists, skipping", o) : (h.label.variation && i.addClass(h.label.variation), !0 === n ? (m.debug("Animating in label", i), i.addClass(g.hidden).insertBefore(a).transition(h.label.transition, h.label.duration)) : (m.debug("Adding selection label", i), i.insertBefore(a)))
                    },
                    message: function (e) {
                        var t = q.children(w.message),
                            n = h.templates.message(m.add.variables(e));
                        0 < t.length ? t.html(n) : t = X("<div/>").html(n).addClass(g.message).appendTo(q)
                    },
                    optionValue: function (e) {
                        var t = m.escape.value(e);
                        0 < L.find('option[value="' + m.escape.string(t) + '"]').length || (m.disconnect.selectObserver(), m.is.single() && (m.verbose("Removing previous user addition"), L.find("option." + g.addition).remove()), X("<option/>").prop("value", t).addClass(g.addition).html(e).appendTo(L), m.verbose("Adding user addition as an <option>", e), m.observe.select())
                    },
                    userSuggestion: function (e) {
                        var t, n = q.children(w.addition),
                            i = m.get.item(e),
                            a = i && i.not(w.addition).length,
                            o = 0 < n.length;
                        h.useLabels && m.has.maxSelections() || ("" === e || a ? n.remove() : (o ? (n.data(b.value, e).data(b.text, e).attr("data-" + b.value, e).attr("data-" + b.text, e).removeClass(g.filtered), h.hideAdditions || (t = h.templates.addition(m.add.variables(c.addResult, e)), n.html(t)), m.verbose("Replacing user suggestion with new value", n)) : ((n = m.create.userChoice(e)).prependTo(q), m.verbose("Adding item choice to menu corresponding with user choice addition", n)), h.hideAdditions && !m.is.allFiltered() || n.addClass(g.selected).siblings().removeClass(g.selected), m.refreshItems()))
                    },
                    variables: function (e, t) {
                        var n, i, a = -1 !== e.search("{count}"),
                            o = -1 !== e.search("{maxCount}"),
                            s = -1 !== e.search("{term}");
                        return m.verbose("Adding templated variables to message", e), a && (n = m.get.selectionCount(), e = e.replace("{count}", n)), o && (n = m.get.selectionCount(), e = e.replace("{maxCount}", h.maxSelections)), s && (i = t || m.get.query(), e = e.replace("{term}", i)), e
                    },
                    value: function (e, t, n) {
                        var i, a = m.get.values();
                        m.has.value(e) ? m.debug("Value already selected") : "" !== e ? (i = X.isArray(a) ? (i = a.concat([e]), m.get.uniqueArray(i)) : [e], m.has.selectInput() ? m.can.extendSelect() && (m.debug("Adding value to select", e, i, L), m.add.optionValue(e)) : (i = i.join(h.delimiter), m.debug("Setting hidden input to delimited value", i, L)), !1 === h.fireOnInit && m.is.initialLoad() ? m.verbose("Skipping onadd callback on initial load", h.onAdd) : h.onAdd.call(M, e, t, n), m.set.value(i, e, t, n), m.check.maxSelections()) : m.debug("Cannot select blank values from multiselect")
                    }
                },
                remove: {
                    active: function () {
                        S.removeClass(g.active)
                    },
                    activeLabel: function () {
                        S.find(w.label).removeClass(g.active)
                    },
                    empty: function () {
                        S.removeClass(g.empty)
                    },
                    loading: function () {
                        S.removeClass(g.loading)
                    },
                    initialLoad: function () {
                        e = !1
                    },
                    upward: function (e) {
                        (e || S).removeClass(g.upward)
                    },
                    leftward: function (e) {
                        (e || q).removeClass(g.leftward)
                    },
                    visible: function () {
                        S.removeClass(g.visible)
                    },
                    activeItem: function () {
                        R.removeClass(g.active)
                    },
                    filteredItem: function () {
                        h.useLabels && m.has.maxSelections() || (h.useLabels && m.is.multiple() ? R.not("." + g.active).removeClass(g.filtered) : R.removeClass(g.filtered), m.remove.empty())
                    },
                    optionValue: function (e) {
                        var t = m.escape.value(e),
                            n = L.find('option[value="' + m.escape.string(t) + '"]');
                        0 < n.length && n.hasClass(g.addition) && (s && (s.disconnect(), m.verbose("Temporarily disconnecting mutation observer")), n.remove(), m.verbose("Removing user addition as an <option>", t), s && s.observe(L[0], {
                            childList: !0,
                            subtree: !0
                        }))
                    },
                    message: function () {
                        q.children(w.message).remove()
                    },
                    searchWidth: function () {
                        T.css("width", "")
                    },
                    searchTerm: function () {
                        m.verbose("Cleared search term"), T.val(""), m.set.filtered()
                    },
                    userAddition: function () {
                        R.filter(w.addition).remove()
                    },
                    selected: function (e, t) {
                        if (!(t = h.allowAdditions ? t || m.get.itemWithAdditions(e) : t || m.get.item(e))) return !1;
                        t.each(function () {
                            var e = X(this),
                                t = m.get.choiceText(e),
                                n = m.get.choiceValue(e, t);
                            m.is.multiple() ? h.useLabels ? (m.remove.value(n, t, e), m.remove.label(n)) : (m.remove.value(n, t, e), 0 === m.get.selectionCount() ? m.set.placeholderText() : m.set.text(m.add.variables(c.count))) : m.remove.value(n, t, e), e.removeClass(g.filtered).removeClass(g.active), h.useLabels && e.removeClass(g.selected)
                        })
                    },
                    selectedItem: function () {
                        R.removeClass(g.selected)
                    },
                    value: function (e, t, n) {
                        var i, a = m.get.values();
                        m.has.selectInput() ? (m.verbose("Input is <select> removing selected option", e), i = m.remove.arrayValue(e, a), m.remove.optionValue(e)) : (m.verbose("Removing from delimited values", e), i = (i = m.remove.arrayValue(e, a)).join(h.delimiter)), !1 === h.fireOnInit && m.is.initialLoad() ? m.verbose("No callback on initial load", h.onRemove) : h.onRemove.call(M, e, t, n), m.set.value(i, t, n), m.check.maxSelections()
                    },
                    arrayValue: function (t, e) {
                        return X.isArray(e) || (e = [e]), e = X.grep(e, function (e) {
                            return t != e
                        }), m.verbose("Removed value from delimited string", t, e), e
                    },
                    label: function (e, t) {
                        var n = S.find(w.label).filter("[data-" + b.value + '="' + m.escape.string(e) + '"]');
                        m.verbose("Removing label", n), n.remove()
                    },
                    activeLabels: function (e) {
                        e = e || S.find(w.label).filter("." + g.active), m.verbose("Removing active label selections", e), m.remove.labels(e)
                    },
                    labels: function (e) {
                        e = e || S.find(w.label), m.verbose("Removing labels", e), e.each(function () {
                            var e = X(this),
                                t = e.data(b.value),
                                n = t !== J ? String(t) : t,
                                i = m.is.userValue(n);
                            !1 !== h.onLabelRemove.call(e, t) ? (m.remove.message(), i ? (m.remove.value(n), m.remove.label(n)) : m.remove.selected(n)) : m.debug("Label remove callback cancelled removal")
                        })
                    },
                    tabbable: function () {
                        m.is.searchSelection() ? (m.debug("Searchable dropdown initialized"), T.removeAttr("tabindex")) : (m.debug("Simple selection dropdown initialized"), S.removeAttr("tabindex")), q.removeAttr("tabindex")
                    },
                    clearable: function () {
                        I.removeClass(g.clear)
                    }
                },
                has: {
                    menuSearch: function () {
                        return m.has.search() && 0 < T.closest(q).length
                    },
                    search: function () {
                        return 0 < T.length
                    },
                    sizer: function () {
                        return 0 < k.length
                    },
                    selectInput: function () {
                        return L.is("select")
                    },
                    minCharacters: function (e) {
                        return !h.minCharacters || (e = e !== J ? String(e) : String(m.get.query())).length >= h.minCharacters
                    },
                    firstLetter: function (e, t) {
                        var n;
                        return !(!e || 0 === e.length || "string" != typeof t) && (n = m.get.choiceText(e, !1), (t = t.toLowerCase()) == String(n).charAt(0).toLowerCase())
                    },
                    input: function () {
                        return 0 < L.length
                    },
                    items: function () {
                        return 0 < R.length
                    },
                    menu: function () {
                        return 0 < q.length
                    },
                    message: function () {
                        return 0 !== q.children(w.message).length
                    },
                    label: function (e) {
                        var t = m.escape.value(e),
                            n = S.find(w.label);
                        return h.ignoreCase && (t = t.toLowerCase()), 0 < n.filter("[data-" + b.value + '="' + m.escape.string(t) + '"]').length
                    },
                    maxSelections: function () {
                        return h.maxSelections && m.get.selectionCount() >= h.maxSelections
                    },
                    allResultsFiltered: function () {
                        var e = R.not(w.addition);
                        return e.filter(w.unselectable).length === e.length
                    },
                    userSuggestion: function () {
                        return 0 < q.children(w.addition).length
                    },
                    query: function () {
                        return "" !== m.get.query()
                    },
                    value: function (e) {
                        return h.ignoreCase ? m.has.valueIgnoringCase(e) : m.has.valueMatchingCase(e)
                    },
                    valueMatchingCase: function (e) {
                        var t = m.get.values();
                        return !!(X.isArray(t) ? t && -1 !== X.inArray(e, t) : t == e)
                    },
                    valueIgnoringCase: function (n) {
                        var e = m.get.values(),
                            i = !1;
                        return X.isArray(e) || (e = [e]), X.each(e, function (e, t) {
                            if (String(n).toLowerCase() == String(t).toLowerCase()) return !(i = !0)
                        }), i
                    }
                },
                is: {
                    active: function () {
                        return S.hasClass(g.active)
                    },
                    animatingInward: function () {
                        return q.transition("is inward")
                    },
                    animatingOutward: function () {
                        return q.transition("is outward")
                    },
                    bubbledLabelClick: function (e) {
                        return X(e.target).is("select, input") && 0 < S.closest("label").length
                    },
                    bubbledIconClick: function (e) {
                        return 0 < X(e.target).closest(I).length
                    },
                    alreadySetup: function () {
                        return S.is("select") && S.parent(w.dropdown).data(C) !== J && 0 === S.prev().length
                    },
                    animating: function (e) {
                        return e ? e.transition && e.transition("is animating") : q.transition && q.transition("is animating")
                    },
                    leftward: function (e) {
                        return (e || q).hasClass(g.leftward)
                    },
                    disabled: function () {
                        return S.hasClass(g.disabled)
                    },
                    focused: function () {
                        return G.activeElement === S[0]
                    },
                    focusedOnSearch: function () {
                        return G.activeElement === T[0]
                    },
                    allFiltered: function () {
                        return (m.is.multiple() || m.has.search()) && !(0 == h.hideAdditions && m.has.userSuggestion()) && !m.has.message() && m.has.allResultsFiltered()
                    },
                    hidden: function (e) {
                        return !m.is.visible(e)
                    },
                    initialLoad: function () {
                        return e
                    },
                    inObject: function (n, e) {
                        var i = !1;
                        return X.each(e, function (e, t) {
                            if (t == n) return i = !0
                        }), i
                    },
                    multiple: function () {
                        return S.hasClass(g.multiple)
                    },
                    remote: function () {
                        return h.apiSettings && m.can.useAPI()
                    },
                    single: function () {
                        return !m.is.multiple()
                    },
                    selectMutation: function (e) {
                        var n = !1;
                        return X.each(e, function (e, t) {
                            if (t.target && X(t.target).is("select")) return n = !0
                        }), n
                    },
                    search: function () {
                        return S.hasClass(g.search)
                    },
                    searchSelection: function () {
                        return m.has.search() && 1 === T.parent(w.dropdown).length
                    },
                    selection: function () {
                        return S.hasClass(g.selection)
                    },
                    userValue: function (e) {
                        return -1 !== X.inArray(e, m.get.userValues())
                    },
                    upward: function (e) {
                        return (e || S).hasClass(g.upward)
                    },
                    visible: function (e) {
                        return e ? e.hasClass(g.visible) : q.hasClass(g.visible)
                    },
                    verticallyScrollableContext: function () {
                        var e = y.get(0) !== Y && y.css("overflow-y");
                        return "auto" == e || "scroll" == e
                    },
                    horizontallyScrollableContext: function () {
                        var e = y.get(0) !== Y && y.css("overflow-X");
                        return "auto" == e || "scroll" == e
                    }
                },
                can: {
                    activate: function (e) {
                        return !!h.useLabels || (!m.has.maxSelections() || !(!m.has.maxSelections() || !e.hasClass(g.active)))
                    },
                    openDownward: function (e) {
                        var t, n, i = e || q,
                            a = !0;
                        return i.addClass(g.loading), n = {
                            context: {
                                offset: y.get(0) === Y ? {
                                    top: 0,
                                    left: 0
                                } : y.offset(),
                                scrollTop: y.scrollTop(),
                                height: y.outerHeight()
                            },
                            menu: {
                                offset: i.offset(),
                                height: i.outerHeight()
                            }
                        }, m.is.verticallyScrollableContext() && (n.menu.offset.top += n.context.scrollTop), a = (t = {
                            above: n.context.scrollTop <= n.menu.offset.top - n.context.offset.top - n.menu.height,
                            below: n.context.scrollTop + n.context.height >= n.menu.offset.top - n.context.offset.top + n.menu.height
                        }).below ? (m.verbose("Dropdown can fit in context downward", t), !0) : t.below || t.above ? (m.verbose("Dropdown cannot fit below, opening upward", t), !1) : (m.verbose("Dropdown cannot fit in either direction, favoring downward", t), !0), i.removeClass(g.loading), a
                    },
                    openRightward: function (e) {
                        var t, n, i = e || q,
                            a = !0;
                        return i.addClass(g.loading), n = {
                            context: {
                                offset: y.get(0) === Y ? {
                                    top: 0,
                                    left: 0
                                } : y.offset(),
                                scrollLeft: y.scrollLeft(),
                                width: y.outerWidth()
                            },
                            menu: {
                                offset: i.offset(),
                                width: i.outerWidth()
                            }
                        }, m.is.horizontallyScrollableContext() && (n.menu.offset.left += n.context.scrollLeft), (t = n.menu.offset.left - n.context.offset.left + n.menu.width >= n.context.scrollLeft + n.context.width) && (m.verbose("Dropdown cannot fit in context rightward", t), a = !1), i.removeClass(g.loading), a
                    },
                    click: function () {
                        return U || "click" == h.on
                    },
                    extendSelect: function () {
                        return h.allowAdditions || h.apiSettings
                    },
                    show: function () {
                        return !m.is.disabled() && (m.has.items() || m.has.message())
                    },
                    useAPI: function () {
                        return X.fn.api !== J
                    }
                },
                animate: {
                    show: function (e, t) {
                        var n, i = t || q,
                            a = t ? function () { } : function () {
                                m.hideSubMenus(), m.hideOthers(), m.set.active()
                            };
                        e = X.isFunction(e) ? e : function () { }, m.verbose("Doing menu show animation", i), m.set.direction(t), n = m.get.transition(t), m.is.selection() && m.set.scrollPosition(m.get.selectedItem(), !0), (m.is.hidden(i) || m.is.animating(i)) && ("none" == n ? (a(), i.transition("show"), e.call(M)) : X.fn.transition !== J && S.transition("is supported") ? i.transition({
                            animation: n + " in",
                            debug: h.debug,
                            verbose: h.verbose,
                            duration: h.duration,
                            queue: !0,
                            onStart: a,
                            onComplete: function () {
                                e.call(M)
                            }
                        }) : m.error(v.noTransition, n))
                    },
                    hide: function (e, t) {
                        var n = t || q,
                            i = (t ? h.duration : h.duration, t ? function () { } : function () {
                                m.can.click() && m.unbind.intent(), m.remove.active()
                            }),
                            a = m.get.transition(t);
                        e = X.isFunction(e) ? e : function () { }, (m.is.visible(n) || m.is.animating(n)) && (m.verbose("Doing menu hide animation", n), "none" == a ? (i(), n.transition("hide"), e.call(M)) : X.fn.transition !== J && S.transition("is supported") ? n.transition({
                            animation: a + " out",
                            duration: h.duration,
                            debug: h.debug,
                            verbose: h.verbose,
                            queue: !1,
                            onStart: i,
                            onComplete: function () {
                                e.call(M)
                            }
                        }) : m.error(v.transition))
                    }
                },
                hideAndClear: function () {
                    m.remove.searchTerm(), m.has.maxSelections() || (m.has.search() ? m.hide(function () {
                        m.remove.filteredItem()
                    }) : m.hide())
                },
                delay: {
                    show: function () {
                        m.verbose("Delaying show event to ensure user intent"), clearTimeout(m.timer), m.timer = setTimeout(m.show, h.delay.show)
                    },
                    hide: function () {
                        m.verbose("Delaying hide event to ensure user intent"), clearTimeout(m.timer), m.timer = setTimeout(m.hide, h.delay.hide)
                    }
                },
                escape: {
                    value: function (e) {
                        var t = X.isArray(e),
                            n = "string" == typeof e,
                            i = !n && !t,
                            a = n && -1 !== e.search(d.quote),
                            o = [];
                        return i || !a ? e : (m.debug("Encoding quote values for use in select", e), t ? (X.each(e, function (e, t) {
                            o.push(t.replace(d.quote, "&quot;"))
                        }), o) : e.replace(d.quote, "&quot;"))
                    },
                    string: function (e) {
                        return (e = String(e)).replace(d.escape, "\\$&")
                    }
                },
                setting: function (e, t) {
                    if (m.debug("Changing setting", e, t), X.isPlainObject(e)) X.extend(!0, h, e);
                    else {
                        if (t === J) return h[e];
                        X.isPlainObject(h[e]) ? X.extend(!0, h[e], t) : h[e] = t
                    }
                },
                internal: function (e, t) {
                    if (X.isPlainObject(e)) X.extend(!0, m, e);
                    else {
                        if (t === J) return m[e];
                        m[e] = t
                    }
                },
                debug: function () {
                    !h.silent && h.debug && (h.performance ? m.performance.log(arguments) : (m.debug = Function.prototype.bind.call(console.info, console, h.name + ":"), m.debug.apply(console, arguments)))
                },
                verbose: function () {
                    !h.silent && h.verbose && h.debug && (h.performance ? m.performance.log(arguments) : (m.verbose = Function.prototype.bind.call(console.info, console, h.name + ":"), m.verbose.apply(console, arguments)))
                },
                error: function () {
                    h.silent || (m.error = Function.prototype.bind.call(console.error, console, h.name + ":"), m.error.apply(console, arguments))
                },
                performance: {
                    log: function (e) {
                        var t, n;
                        h.performance && (n = (t = (new Date).getTime()) - (K || t), K = t, W.push({
                            Name: e[0],
                            Arguments: [].slice.call(e, 1) || "",
                            Element: M,
                            "Execution Time": n
                        })), clearTimeout(m.performance.timer), m.performance.timer = setTimeout(m.performance.display, 500)
                    },
                    display: function () {
                        var e = h.name + ":",
                            n = 0;
                        K = !1, clearTimeout(m.performance.timer), X.each(W, function (e, t) {
                            n += t["Execution Time"]
                        }), e += " " + n + "ms", N && (e += " '" + N + "'"), (console.group !== J || console.table !== J) && 0 < W.length && (console.groupCollapsed(e), console.table ? console.table(W) : X.each(W, function (e, t) {
                            console.log(t.Name + ": " + t["Execution Time"] + "ms")
                        }), console.groupEnd()), W = []
                    }
                },
                invoke: function (i, e, t) {
                    var a, o, n, s = F;
                    return e = e || Q, t = M || t, "string" == typeof i && s !== J && (i = i.split(/[\. ]/), a = i.length - 1, X.each(i, function (e, t) {
                        var n = e != a ? t + i[e + 1].charAt(0).toUpperCase() + i[e + 1].slice(1) : i;
                        if (X.isPlainObject(s[n]) && e != a) s = s[n];
                        else {
                            if (s[n] !== J) return o = s[n], !1;
                            if (!X.isPlainObject(s[t]) || e == a) return s[t] !== J ? o = s[t] : m.error(v.method, i), !1;
                            s = s[t]
                        }
                    })), X.isFunction(o) ? n = o.apply(t, e) : o !== J && (n = o), X.isArray(P) ? P.push(n) : P !== J ? P = [P, n] : n !== J && (P = n), o
                }
            }, $ ? (F === J && m.initialize(), m.invoke(B)) : (F !== J && F.invoke("destroy"), m.initialize())
        }), P !== J ? P : H
    }, X.fn.dropdown.settings = {
        silent: !1,
        debug: !1,
        verbose: !1,
        performance: !0,
        on: "click",
        action: "activate",
        values: !1,
        clearable: !1,
        apiSettings: !1,
        selectOnKeydown: !0,
        minCharacters: 0,
        filterRemoteData: !1,
        saveRemoteData: !0,
        throttle: 200,
        context: Y,
        direction: "auto",
        keepOnScreen: !0,
        match: "both",
        fullTextSearch: !1,
        placeholder: "auto",
        preserveHTML: !0,
        sortSelect: !1,
        forceSelection: !0,
        allowAdditions: !1,
        ignoreCase: !1,
        hideAdditions: !0,
        maxSelections: !1,
        useLabels: !0,
        delimiter: ",",
        showOnFocus: !0,
        allowReselection: !1,
        allowTab: !0,
        allowCategorySelection: !1,
        fireOnInit: !1,
        transition: "auto",
        duration: 200,
        glyphWidth: 1.037,
        label: {
            transition: "scale",
            duration: 200,
            variation: !1
        },
        delay: {
            hide: 300,
            show: 200,
            search: 20,
            touch: 50
        },
        onChange: function (e, t, n) { },
        onAdd: function (e, t, n) { },
        onRemove: function (e, t, n) { },
        onLabelSelect: function (e) { },
        onLabelCreate: function (e, t) {
            return X(this)
        },
        onLabelRemove: function (e) {
            return !0
        },
        onNoResults: function (e) {
            return !0
        },
        onShow: function () { },
        onHide: function () { },
        name: "Dropdown",
        namespace: "dropdown",
        message: {
            addResult: "Add <b>{term}</b>",
            count: "{count} selected",
            maxSelections: "Max {maxCount} selections",
            noResults: "No results found.",
            serverError: "There was an error contacting the server"
        },
        error: {
            action: "You called a dropdown action that was not defined",
            alreadySetup: "Once a select has been initialized behaviors must be called on the created ui dropdown",
            labels: "Allowing user additions currently requires the use of labels.",
            missingMultiple: "<select> requires multiple property to be set to correctly preserve multiple values",
            method: "The method you called is not defined.",
            noAPI: "The API module is required to load resources remotely",
            noStorage: "Saving remote data requires session storage",
            noTransition: "This module requires ui transitions <https://github.com/Semantic-Org/UI-Transition>"
        },
        regExp: {
            escape: /[-[\]{}()*+?.,\\^$|#\s]/g,
            quote: /"/g
        },
        metadata: {
            defaultText: "defaultText",
            defaultValue: "defaultValue",
            placeholderText: "placeholder",
            text: "text",
            value: "value"
        },
        fields: {
            remoteValues: "results",
            values: "values",
            disabled: "disabled",
            name: "name",
            value: "value",
            text: "text"
        },
        keys: {
            backspace: 8,
            delimiter: 188,
            deleteKey: 46,
            enter: 13,
            escape: 27,
            pageUp: 33,
            pageDown: 34,
            leftArrow: 37,
            upArrow: 38,
            rightArrow: 39,
            downArrow: 40
        },
        selector: {
            addition: ".addition",
            dropdown: ".ui.dropdown",
            hidden: ".hidden",
            icon: "> .dropdown.icon",
            input: '> input[type="hidden"], > select',
            item: ".item",
            label: "> .label",
            remove: "> .label > .delete.icon",
            siblingLabel: ".label",
            menu: ".menu",
            message: ".message",
            menuIcon: ".dropdown.icon",
            search: "input.search, .menu > .search > input, .menu input.search",
            sizer: "> input.sizer",
            text: "> .text:not(.icon)",
            unselectable: ".disabled, .filtered"
        },
        className: {
            active: "active",
            addition: "addition",
            animating: "animating",
            clear: "clear",
            disabled: "disabled",
            empty: "empty",
            dropdown: "ui dropdown",
            filtered: "filtered",
            hidden: "hidden transition",
            item: "item",
            label: "ui label",
            loading: "loading",
            menu: "menu",
            message: "message",
            multiple: "multiple",
            placeholder: "default",
            sizer: "sizer",
            search: "search",
            selected: "selected",
            selection: "selection",
            upward: "upward",
            leftward: "left",
            visible: "visible"
        }
    }, X.fn.dropdown.settings.templates = {
        dropdown: function (e) {
            var t = e.placeholder || !1,
                n = (e.values, "");
            return n += '<i class="dropdown icon icon-freccia-basso"></i>', e.placeholder ? n += '<div class="default text">' + t + "</div>" : n += '<div class="text"></div>', n += '<div class="menu">', X.each(e.values, function (e, t) {
                n += t.disabled ? '<div class="disabled item" data-value="' + t.value + '">' + t.name + "</div>" : '<div class="item" data-value="' + t.value + '">' + t.name + "</div>"
            }), n += "</div>"
        },
        menu: function (e, a) {
            var t = e[a.values] || {},
                o = "";
            return X.each(t, function (e, t) {
                var n = t[a.text] ? 'data-text="' + t[a.text] + '"' : "",
                    i = t[a.disabled] ? "disabled " : "";
                o += '<div class="' + i + 'item" data-value="' + t[a.value] + '"' + n + ">", o += t[a.name], o += "</div>"
            }), o
        },
        label: function (e, t) {
            return t + '<i class="delete icon icon-close1"></i>'
        },
        message: function (e) {
            return e
        },
        addition: function (e) {
            return e
        }
    }
}(jQuery, window, document);